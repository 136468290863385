import React, { Component } from "react";
import AppContext from "../app/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../resources/logo.png";
import tokenExpiredPlaceholder from "../resources/Images/Token-expired-placeholder-96px.svg";
import ApiService from "../services/ApiService";
import { firstLoggedInKey } from "./onboarding/OnboardingSplash";
import LoginForm from "./LoginForm";
import { Cookies } from "react-cookie";
import config from "../../app-settings.json";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../authConfigAzureAd.js";
const { apiUrl } = config;
const authType = window["APP_SETTINGS"].AUTH_TYPE;

const cookies = new Cookies();
const msalInstance = new PublicClientApplication(msalConfig);

class Login extends Component {
  state = {
    onLoginChange: false,
    valMsgs: "",
    passwordType: "password",
    confPasswordType: "password",
    gettingCookieCompleted: false,
    startTokenTimer: false,
    newToken: "",
    isLoginSSO: this.context.loginSSO,
    executingSSOLogin: false
  };

  static contextType = AppContext;

  setValMsgs = value => {
    this.setState({ valMsgs: value });
  };


  authAzureAd = async () => {
    await msalInstance.initialize();
  };

  // 1. Only for first render
  componentDidMount() {
    this.authAzureAd();
    // This logis it's only used for SSO login not normal login
    console.log("[1] Authentication check => login.js");
    if (this.state.isLoginSSO && !this.state.executingSSOLogin) {
      if (!this.state.gettingCookieCompleted) {
        //let token = localStorage?.getItem("triyo-token-app");
        let token = cookies.get("triyo-token-app");
        let tokenApi = cookies.get("triyo-token-app-api");
        console.log("[1.1] Login[componentDidMount] -> triyo-token-app => ", token);
        console.log("[1.2] Login[componentDidMount] -> triyo-token-app-api => ", tokenApi);
        if (token != null) {
          this.setState({
            gettingCookieCompleted: true,
            newToken: token
          });
        } else if (tokenApi != null) {
          this.setState({
            gettingCookieCompleted: true,
            newToken: tokenApi
          });
        } else {
          this.setState({ startTokenTimer: true });
        }
      }
    }
  }

  //2. Here we check states changes
  componentDidUpdate() {
    // This logis it's only used for SSO login not normal login
    if (this.state.isLoginSSO && !this.state.executingSSOLogin) {
      let intervalCheckToken;
      if (!this.state.gettingCookieCompleted && this.state.startTokenTimer) {
        intervalCheckToken = setInterval(() => {
          let token = cookies.get("triyo-token-app");
          let tokenApi = cookies.get("triyo-token-app-api");
          console.log("[1.3.1] Login[componentDidUpdate] -> triyo-token-app => ", token);
          console.log("[1.3.2] Login[componentDidUpdate] -> triyo-token-app-api => ", tokenApi);
          if (token != null) {
            this.setState({
              gettingCookieCompleted: true,
              newToken: token
            });
            clearInterval(intervalCheckToken);
          } else if (tokenApi != null) {
            this.setState({
              gettingCookieCompleted: true,
              newToken: tokenApi
            });
            clearInterval(intervalCheckToken);
          } else {
            console.log("[1.3.3] Login[componentDidUpdate] -> No triyo-token-app/api detected...");
          }
        }, 5000);

        if (this.state.gettingCookieCompleted) {
          if (this.state.isLoginSSO && !this.context.tokenReady) {
            console.log("[1.4.1] Login[componentDidUpdate] -> this.ssoLogin() called -->", this.state.isLoginSSO);
            this.ssoLogin();
            clearInterval(intervalCheckToken);
          }
        }
      }

      if (this.state.gettingCookieCompleted) {
        if (this.state.isLoginSSO && !this.context.tokenReady) {
          console.log("[1.4.2] Login[componentDidUpdate] -> this.ssoLogin() called -->", this.state.isLoginSSO);
          this.ssoLogin();
          clearInterval(intervalCheckToken);
        }
      }
    }
  }

  getMvcUserProfile = () => {
    return Promise.all([mvcGetUserProfile()]);
  };

  ssoLogin = async () => {
    this.setState({ executingSSOLogin: true });
    console.log("Starting --> ssoLogin => fetching(/users/GetCurrentUser)");
    const fetchPromise = fetch(apiUrl + "/users/GetCurrentUser", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.newToken}`
      }
    });
    fetchPromise
      .then(response => {
        return response.json();
      })
      .then(result => {
        console.log("api/core/getUserProfile -->");
        console.log(result);
        localStorage.setItem("tmpTriyoToken", result.Token); // previous token --> should be replaced later with jwt
        localStorage.setItem("tmpJwtTriyoToken", result.AccessToken);
        this.context.userToken = result.Token; // previous token --> should be replaced later with jwt
        this.context.jwtUserToken = result.AccessToken;
        this.context.currUser = result.User;
        this.context.userName = result.User.UserName;
        this.context.userNameFull = result.User.Name;
        this.context.userEmail = result.User.Email;
        this.context.userLogin = true;

        this.context.tokenReady = true;
        console.log("this.context.tokenReady", this.context.tokenReady);

        // handles redirect to appropriate page after login completes on App.js
        this.props.onLoginChange(true);
      });
  };

  // form submission handler
  onSubmit = values => {
    let data = {
      username: values.username,
      password: values.password,
      timezone: new Date().getTimezoneOffset()
    };
    // Check for user data
    debugger;
    ApiService.login(data, this.context)
      .then(result => {
        console.log(result);
        if (result.status === 200) {
          // sets application wide context values
          if (result.data.User.Active === false) {
            this.setState({ valMsgs: "This User Account is no longer Active. Contact Admin." });
            return;
          }
          localStorage.setItem("tmpTriyoToken", result.data.Token); // previous token --> should be replaced later with jwt
          localStorage.setItem("tmpJwtTriyoToken", result.data.AccessToken);
          localStorage.setItem("rememberUser", values.rememberChecked);
          this.context.userToken = result.data.Token; // previous token --> should be replaced later with jwt
          this.context.jwtUserToken = result.data.AccessToken;
          this.context.currUser = result.data.User;
          this.context.userName = result.data.User.UserName;
          this.context.userNameFull = result.data.User.Name;
          this.context.userEmail = result.data.User.Email;
          this.context.userLogin = true;

          this.context.tokenReady = true;
          console.log("this.context.tokenReady", this.context.tokenReady);

          // handles redirect to appropriate page after login completes on App.js
          this.props.onLoginChange(true);
        } else {
          console.log("API [ApiDataService.login] error status: " + result.status);
        }
      })
      .catch(error => {
        // needs backend change to distinguish incorrect username or incorrect password
        this.setState({ valMsgs: "Invalid username or password" });
        console.log("API [ApiDataService.login] error ->");
        console.log(error);
      });
  };

  // validation for username/password fields
  validate = values => {
    let errors = {};
    if (!values.username) {
      errors.username = "Enter user name";
    }
    if (!values.password) {
      errors.password = "Enter password";
    }
    return errors;
  };

  // auto scroll to invalid form fields
  scrollToError = (touched, errors) => {
    if (touched.username && errors.username) {
      document.getElementById("username").scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center"
      });
    } else if (touched.password && errors.password) {
      document.getElementById("password").scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center"
      });
    } else {
      document.getElementById("username").scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center"
      });
    }

    return null;
  };

  isFirstLogin = () => {
    return !localStorage.getItem(firstLoggedInKey);
  };

  showHidePassword = (event, passType) => {
    event.preventDefault();
    passType === "password"
      ? this.setState({ passwordType: this.state.passwordType === "password" ? "text" : "password" })
      : this.setState({ confPasswordType: this.state.confPasswordType === "password" ? "text" : "password" });
  };

  openWebPage = () => {
    Office.context.ui.displayDialogAsync(this.context.applicationEndpoint, { height: 85, width: 60 });
  };

  loginAzureAd = () => {
    console.log("Login.js loginAzureAd -->");
    msalInstance
      .loginPopup({
        scopes: ["user.read"]
      })
      .then(response => {
        console.log("Login.js ID Token:", response.idToken);
        console.log("Login.js Access Token:", response.accessToken);
        localStorage.setItem("tmpTriyoToken", response.accessToken);
        localStorage.setItem("tmpJwtTriyoToken", response.accessToken);
        this.context.userToken = response.accessToken;
        this.context.jwtUserToken = response.accessToken;

        ApiService.getCurrentUser(this.context)
          .then(result => {
            console.log(result);
            if (result.status === 200) {
              if (result.data.User.Active === false) {
                this.setState({ valMsgs: "This User Account is no longer Active. Contact Admin." });
                return;
              }
              this.context.currUser = result.data.User;
              this.context.userName = result.data.User.UserName;
              this.context.userNameFull = result.data.User.Name;
              this.context.userEmail = result.data.User.Email;
              this.context.userLogin = true;
              this.context.tokenReady = true;
              console.log("this.context.tokenReady", this.context.tokenReady);
              this.props.onLoginChange(true);
            } else {
              console.log("API [ApiDataService.login] error status: " + result.status);
            }
          })
          .catch(error => {
            this.setState({ valMsgs: "Invalid username or password" });
            console.log("API [ApiDataService.login] error ->");
            console.log(error);
          });
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    if (this.context.userLogin) {
      this.props.onLoginChange(true);
      return <div>user is logged in</div>;
    }
    if (this.isFirstLogin()) {
      if (this.context.outlookComposeMode) {
        // if the task already exists for this email:
        if (this.context.currEmail?.item?.conversationId) {
          // Show Task Details Page
          this.setPage("emailTrackingTaskDetails");
        } else {
          // Show Link Email Page
          this.setPage("emailTracking");
        }
      } else {
        this.props.setPage("onboarding");
        return <div>user's first time logging in</div>;
      }
    }

    return (
      <React.Fragment>
        <div className="container fluid mt-3 mt-1">
          <div className="row text-center">
            <div className="col-12 mt-3">
              <div className="mb-3">
                <img src={logo} className="loginLogo mb-3"></img>
              </div>
              {this.state.isLoginSSO ? (
                <React.Fragment>
                  <div className="img-container m-1">
                    <img src={tokenExpiredPlaceholder} className="center token-expired-placeholder"></img>
                  </div>
                  <h5 className="mt-3 mb-1 bold appFont">Your Session has Expired</h5>
                  <h5 className="mt-1 mb-2 bold appFont">Please Log in</h5>
                  <p className="mt-1 mb-2 bold appFontSmallOne">
                    To continue managing Tasks and Projects, please re-authenticate your TRIYO Add-in by clicking the
                    button below.
                  </p>
                  <p className="mt-1 mb-2 bold appFontSmallOne">
                    You will be directed to the TRIYO Web Application in a new browser window. Once successfully logged
                    in to the TRIYO Web Application, your TRIYO Add-in will be re-authenticated automatically.
                  </p>
                  <p className="mt-1 mb-5 bold appFontSmallOne">
                    Feel free to close off the TRIYO Web Application once signed in to the Add-in.
                  </p>
                  <button
                    onClick={e => this.openWebPage()}
                    className="loginButton btn-primary btn-block shadow-none blueButton"
                  >
                    Log in via SSO
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h4 className="mb-5 bold appFont">Log in to your account please</h4>
                  {authType === "AzureAD" && (
                    <>
                      <>
                        <button className="btn-primary" onClick={this.loginAzureAd}>
                          Sign In with Azure AD
                        </button>
                      </>
                    </>
                  )}
                  {authType !== "AzureAD" && (
                    <LoginForm
                      onSubmit={this.onSubmit}
                      passwordType={this.state.passwordType}
                      showHidePassword={this.showHidePassword}
                      valMsgs={this.state.valMsgs}
                      applicationEndpoint={this.context.applicationEndpoint}
                      freemiumUser={this.context.freemiumUser}
                      scrollToError={this.scrollToError}
                      setValMsgs={this.setValMsgs}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
