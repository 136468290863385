// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../resources/Icons/ChevronDown-Latest-80px.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../resources/Icons/ChevronDown-Latest-Hover-80px.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../resources/Icons/ChevronDown-Latest-Pressed-80px.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../resources/Icons/ChevronDown-New-82px.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../resources/Icons/ChevronDown-New-Hover-82px.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../resources/Icons/ChevronDown-New-Pressed-82px.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, ".floating-action-button,.floating-action-button-latest,.floating-action-button-new{position:sticky;left:90%;bottom:0;width:60px;z-index:1000;margin-top:-50px}.floating-action-button-latest{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.floating-action-button-latest:hover{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");cursor:pointer}.floating-action-button-latest:active{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");cursor:pointer}.floating-action-button-new{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.floating-action-button-new:hover{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");cursor:pointer}.floating-action-button-new:active{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");cursor:pointer}\n", ""]);
// Exports
module.exports = exports;
